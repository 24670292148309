body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .euiTableRowCell--hideForDesktop {
    display: none !important; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .euiTableRowCell--hideForDesktop {
    display: none !important; } }

@media only screen and (min-width: 1200px) {
  .euiTableRowCell--hideForDesktop {
    display: none !important; } }

@media only screen and (max-width: 480px) {
  .connect-button {
    padding: 0.5rem 18px !important;
    margin-left: 0.5rem;
  }
}

.color-green {
  color: rgb(0, 197, 154);
  text-decoration: none;
}

.alert-success {
  .alert-link {
    color: yellow;
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  max-height: 120px;
  width: 100%;
  background: #191D34;

  @media only screen and (max-width: 768px) {
    max-height: 200px;
  }
  @media only screen and (max-height: 1100px) {
    position: initial;
  }

  a.footer-link {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    margin: 0 20px;
  }
  a.footer-link:hover {
    color: rgb(0, 197, 154) !important;
  }
}
@media only screen and (min-width: 575px) and (max-width: 767px) {
  .euiTable.euiTable--responsive .euiTableRowCell {
    display: block;
    min-width: 90%;
    border: none;
  }
}

.offcanvas {
  background-color: rgba(25, 29, 52, 1) !important;
}
.offcanvas-header {
  button.btn-close {
    color: #ffffff;
    filter: var(--bs-btn-close-white-filter);
  }
}

footer {
  padding-top: 5vh;
  padding-bottom: 2vh;

  li {
    list-style-type: none;
    margin-left: 1vw;
  }

  li:first-child {
    margin:0;
  }

  .content {
    height: 50vh;
    background-color: lightgray;
  }

  .container {
    background-color: beige;
  }

  .copyright,  .impressum {
    text-align: center;
  }

  .logo {
    height: 8vh;
  }

  @media (max-width: 768px)
  {
    li {
      margin-left: 0;
      margin-bottom: 1vh;
    }

    .sozial {
      margin-bottom: 1vh;
    }
  }
}


.alert {

  border-radius: 10px !important;
  color: white !important;
  font-weight: 700;
  opacity: 0.5;
  border: none !important;
}
.alert-success {
  background-color: #006D5B !important;
}
.alert-danger {
  background-color: #800020 !important;
}
.alert-warning {
  background-color: #7D5E00 !important;
}
.btn-success {
  background-color: rgb(0, 197, 154) !important;
  color: white;
  padding: 0 8px !important;
  max-height: 50px;
}
.btn-success:hover {
  opacity: 0.8 !important;
}
.select-my-transaction {

  @media (max-width: 768px) {
    margin-top: -20px;
  }
  @media (max-width: 426px) {
    margin-top: -40px;
  }
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
  margin-top: -63px;

  .css-13cymwt-control {
    background-color: rgb(0, 197, 154);
    color: #ffffff;
  }
}

@media (max-width: 480px) {
  .expert-margin {
    margin-top: 20px;
  }
  .more-margin {
    margin-top: 25px;
  }
}

@media (min-width: 480px) {
  .expert-margin, .more-margin {
    margin-top: 0;
  }
}


#w3m-modal .w3m-toolbar-placeholder {
  background-color: rgb(0, 197, 154);
}

// uiTable

.euiButton {
  border: 0;
  border-radius: 100px;
  height: auto;
  padding: 8px 20px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  transition: all 0.5s ease;

  &:hover,
  &:focus {
    transform: none !important;
    text-decoration: none !important;
  }

  .euiButton__content {
    padding: 0;
  }

  &.euiButton-isDisabled a {
    color: #515761;
  }
}

.euiButtonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;

  &.euiButtonContent--iconLeft,
  &.euiButtonContent--iconRight {
    height: auto;
    justify-content: space-between;
  }
}

.euiButton--primary {
  color: #e2007a;
  background-color: rgba($color: #e2007a, $alpha: 0.2);

  a {
    color: #e2007a;
  }

  &:not([class*='isDisabled']):hover,
  &:not([class*='isDisabled']):focus {
    color: #fff;
    background-color: rgba($color: #e2007a, $alpha: 0.3);

    a {
      color: #fff;
    }
  }

  &.euiButton--fill:not([class*='isDisabled']),
  &.euiButton--fill:not([class*='isDisabled']):hover,
  &.euiButton--fill:not([class*='isDisabled']):focus {
    color: #fff;
    background-color: #e2007a;

    a {
      color: #fff;
    }
  }

  &.euiButton--fill:not([class*='isDisabled']):hover,
  &.euiButton--fill:not([class*='isDisabled']):focus {
    box-shadow: 0 0 20px rgba($color: #e2007a, $alpha: 0.8) !important;
  }
}

.euiButtonEmpty {
  font-family: Poppins, sans-serif;
}

.euiButtonEmpty--primary {
  color: #4bc9f0;
}

.euiButtonEmpty--primary:enabled:focus {
  color: #fff;
  background-color: rgba($color: #e2007a, $alpha: 0.2);
}

.euiFlyout {
  background-color: #1f1f1f;
}

.euiModal {
  background-color: #1f1f1f;
  border-radius: 8px;
  min-width: 570px;

  @media (min-width: 48rem) {
    .euiFormControlLayout {
      min-width: 570px;
    }

    .euiFieldSearch {
      max-width: 570px;
    }
  }
}

.euiModalHeader {
  padding: 8px 8px 0;
}

.euiModalBody {
  padding: 0 8px 8px 8px;
  width: auto;

  .euiModalBody__overflow {
    padding: 0;
    mask-image: none;
  }

  &.no-overflow .euiModalBody__overflow {
    overflow: initial;
  }
}

@media (min-width: 768px) {
  .euiModalHeader {
    padding: 30px 30px 0;
  }
  .euiModalBody {
    padding: 0 30px 30px 30px;
    width: auto;
  }
}

.euiModalHeader__title h1 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
}

.euiModalBody:last-of-type .euiModalBody__overflow {
  padding: 0;
}

.euiFieldSearch {
  background-color: #1f1f1f;
  box-shadow: none;
  border: 1px solid #353945;
  border-radius: 8px;
  height: 48px;
  max-width: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;

  &:focus {
    background-image: none;
  }

  &::-webkit-input-placeholder {
    color: #353945;
  }

  &:-ms-input-placeholder {
    color: #353945;
  }

  &::placeholder {
    color: #353945;
  }

  + .euiFormControlLayoutIcons {
    color: #fff;
    height: 46px;
  }
}

.euiFormControlLayoutClearButton {
  padding: 0;
  border: 0;
}

.euiStepsHorizontal {
  width: 100%;
}

.euiStepHorizontal {
  background-color: transparent;
  border: none;
}

.euiStepNumber {
  background-color: #1f1f1f;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 34px;
  outline: none;
  border: 1px solid #e2007a;
  color: #e2007a;
  transition: all 0.5s ease;

  &.euiStepNumber--incomplete {
    border-color: transparent;
    line-height: 32px;
    background-color: #23262f;
    border-width: 1px;

    .euiStepNumber__number {
      top: 0;
    }
  }

  &.euiStepNumber--disabled {
    background-color: #23262f;
    border-color: transparent;
    color: #fff;
  }
}

.euiStepHorizontal:focus:not(.euiStepHorizontal-isDisabled) .euiStepHorizontal__number:not(:focus-visible) {
  outline: none;
}

.euiStepNumber--complete {
  background-color: #e2007a;
  color: #fff;

  .euiStepNumber__icon {
    width: 20px;
    height: 20px;
  }
}

.euiStepHorizontal {
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  &::before,
  &::after {
    background-color: #353945;
  }
}

.euiStepHorizontal-isComplete {
  &::before,
  &::after {
    background-color: #e2007a;
  }
}

.euiStepHorizontal-isSelected {
  &::before {
    background-color: #e2007a;
  }
}

.euiStepHorizontal-isIncomplete {
  .euiStepNumber:hover {
    color: #e2007a;
    border-color: #e2007a !important;
  }
}

.euiStepHorizontal__title {
  display: none;
}

.euiFormControlLayout {
  height: 64px;
  box-shadow: none;
  background-color: #1f1f1f;
  border: 0;
  border-radius: 8px;
  width: 100%;
  max-width: none;

  .euiFormControlLayout__childrenWrapper {
    height: 100%;

    .euiFieldText {
      height: 100%;
    }
  }

  .euiFieldText,
  .euiFieldNumber {
    background-image: none;
    background-color: #1f1f1f;
    box-shadow: none;
    width: 100%;
    max-width: none;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #fff;

    &:focus {
      background-image: none;
    }

    &::-webkit-input-placeholder {
      color: #353945;
    }

    &:-ms-input-placeholder {
      color: #353945;
    }

    &::placeholder {
      color: #353945;
    }
  }

  .euiButtonEmpty--primary {
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #4bc9f0;
  }
}

.euiButtonEmpty:hover {
  text-decoration: none !important;
}

.slick-dots {
  li {
    width: 0.5rem;
    height: 0.5rem;
  }

  li button {
    padding: 0;
    width: 0.5rem;
    height: 0.5rem;
    text-align: center;

    &::before {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 1rem;
      background-color: #fff;
      transition: all 0.5s ease;
    }
  }

  li.slick-active {
    width: 1.5rem;

    button {
      width: 1.5rem;
    }

    button::before {
      width: 1.5rem;
      background-color: #e2007a;
    }
  }
}

.euiTabs {
  background-color: #151515;
  border-radius: 100px;
}

.euiTabs--bottomBorder {
  box-shadow: none;

  @media (min-width: 48rem) {
    // justify-content: flex-start;
  }
}

.euiTab {
  background-color: transparent;
  border: 0;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  min-width: 0;
  justify-content: center;
  transition: all 0.5s ease;

  @media (min-width: 48rem) {
    min-width: 150px;
  }

  .euiTab__content {
    font-family: Poppins, sans-serif;
    line-height: 1.5;
    color: #e2007a;
    transition: all 0.5s ease;
  }

  &:not(.euiTab-isDisabled):hover,
  &:not(.euiTab-isDisabled):focus {
    background-color: rgba($color: #e2007a, $alpha: 0.2);

    .euiTab__content {
      text-decoration: none;
      color: #fff;
    }
  }

  &:not(.euiTab-isDisabled):focus {
    background-color: #e2007a;
  }

  &.euiTab-isSelected {
    box-shadow: none;
  }

  &.euiTab-isSelected {
    background-color: #e2007a;
  }

  &.euiTab-isSelected .euiTab__content {
    color: #fff !important;
  }

  &.euiTab-isSelected:hover,
  &.euiTab-isSelected:focus {
    text-decoration: none;
  }
}

// Table
.euiSearchBar__searchHolder {
  .euiFormControlLayout {
    height: 3rem;
  }

  .euiFieldSearch {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  .euiFormControlLayoutClearButton {
    padding: 0;
    border: none;
  }
}

.euiSearchBar__filtersHolder {
  .euiFilterGroup {
    box-shadow: none;
    border-radius: 0.5rem;
    background-color: transparent;

    > .euiPopover {
      background-color: rgba(255, 255, 255, 0.05);

      @media (min-width: 75rem) {
        margin-right: 0.5rem;
        border-radius: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .euiPopover__anchor {
        border-radius: 0.5rem;
        overflow: hidden;
      }
    }
  }

  .euiFilterButton {
    height: 3rem;
    box-shadow: none;
  }
}

.euiPopover__panel {
  background-color: #1f1f1f !important;

  div[data-popover-arrow='top']::before {
    border-block-start-color: #1f1f1f !important;
  }

  div[data-popover-arrow='bottom']::before {
    border-block-end-color: #1f1f1f !important;
  }

  div[data-popover-arrow='left']::before {
    border-inline-start-color: #1f1f1f !important;
  }

  div[data-popover-arrow='right']::before {
    border-inline-start-color: #1f1f1f !important;
  }

  .euiContextMenuItem {
    background-color: #1f1f1f;
    border: none;
    cursor: pointer;
    font-family: Poppins, san-serif;
    font-size: 0.875rem;
  }
}

.euiSelectableListItem__icon {
  color: #fff !important;
}

.euiSelectableList__list {
  mask-image: none;
}

.euiSelectableListItem__text {
  .euiHealth {
    font-size: 0.875rem !important;
    vertical-align: middle;
  }

  .networkInfo {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    img {
      margin-left: 0;
      margin-right: 0.5rem;
    }

    p {
      font-weight: 400;
    }
  }
}

.euiSelectableListItem-isFocused:not([aria-disabled='true']),
.euiSelectableListItem:hover:not([aria-disabled='true']) {
  color: #4bc9f0;

  .euiSelectableListItem__text {
    text-decoration: none;
  }

  .networkInfo p {
    color: #4bc9f0;
  }
}

// Table
.euiBasicTable {
  .euiFlexGroup .euiFlexItem .euiPopover .euiPopover__anchor button.euiButtonEmpty {
    border-radius: 12px;
    background-color: rgb(0, 197, 154);
    width: 100%;
    color: #ffffff;
    margin: 5px 3px;
    padding: 10px;
    font-weight: 700;
    height: 40px;
    font-size: 14px;
    border-width: 0;
    opacity: 1;
  }
}
.euiPaginationArrowButton:not([class*=isDisabled]):hover, .euiPaginationArrowButton:not([class*=isDisabled]):focus, .euiPaginationArrowButton:not([class*=isDisabled]):focus-within {
  background-color: rgb(0, 197, 154) !important;
}
.css-1cqwxjo-euiButtonIcon-xs-empty-disabled-isDisabled-euiPaginationButton:hover {
  background-color: rgba(0, 197, 154, 0.5) !important;
}

ul.euiPagination__list {
  li.euiPagination__item {
    button {
      background-color: rgba(0, 197, 154, 0.5) !important;
      color: #ffffff;
    }
    a.euiButtonEmpty {
      background: rgba(255, 255, 255, 0.05);
      color: #ffffff;

      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      border: 0;
      text-align: center;
      text-decoration: none;
      font-size: 0.875rem;
      margin: 0 0.25rem;

      :hover {
        background-color: rgb(0, 197, 154);
        background-size: 100%;
        font-weight: bolder;

        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        text-decoration: none;
        font-size: 0.875rem;
        margin-left: -5px;
        border-radius: 5px;
      }
    }
  }
}


.euiContextMenuItem {
  display: block;
  padding: 12px;
  width: 100%;
  text-align: left;
  color: #dfe5ef;
  outline-offset: -2px;
}
.euiPopover__panel .euiContextMenuItem {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  cursor: pointer;
  font-size: .875rem;

  :hover {
    font-weight: bolder;
  }
}

.euiTableHeaderMobile {
  padding-top: 0;

  > .euiFlexGroup > .euiFlexItem {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.euiTableSortMobile .euiButtonEmpty {
  height: 3rem;
  border: none;

  &:focus {
    background-color: transparent;
  }
}

.euiTableSortMobileItem {
  font-family: Poppins, sans-serif;
  background-color: #1f1f1f;
  border: none;
}

.euiTable {
  background-color: transparent;
  width: 100%;

  @media (min-width: 48rem) {
    thead tr {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }
  }
}

.euiTable.euiTable--responsive {
  thead {
    @media (max-width: 1199px) {
      display: none;
    }

    tr {
      text-align: center;
      margin-bottom: 10px;

      .euiTableHeaderCell .euiTableCellContent__text {

        font-size: 0.85rem;
        font-weight: bold;
      }
    }
  }
  tbody {
    tr td {
      padding: 10px 0;
    }
  }
}

.euiTable.euiTable--responsive .euiTableRow {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 0.5rem;

  @media (min-width: 48rem) {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 768px) {
    &.euiTableRow-isExpandable td {
      padding-left: 10px;
    }
  }

  @media (max-width: 1199px) {
    &.euiTableRow-isExpandable,
    &.euiTableRow-hasActions {
      background-size: 40px 100%;
      background-position-x: right;
      background-repeat: no-repeat;
      padding-right: 40px;
      position: relative;
    }

    .euiTableRowCell--isExpander {
      min-width: 0;
      width: 24px;
      position: absolute;
      top: 16px;
      right: 8px;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.euiTableRow-isExpandable,
  &.euiTableRow-hasActions {
    background-image: none;
    box-shadow: none;
  }

  &.euiTableRow-isExpandedRow {
    background-image: none;

    @media (min-width: 48rem) {
      margin-top: -0.5rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .euiTableRowCell {
      width: 100%;
    }
  }

  &[aria-owns*='row_'] {
    @media (min-width: 48rem) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .euiTableRowCell--hasActions .euiTableCellContent .euiButtonEmpty {
    border: none;

    .euiButtonContent {
      padding: 0;
    }
  }

  .euiTableRowCell--isExpander .euiButtonIcon {
    background-color: transparent;
    color: #fff;
  }
}

.euiTableHeaderCell {
  .euiTableCellContent__text {
    text-transform: uppercase;
    font-family: Poppins, san-serif;
    font-size: 0.75rem;
    font-weight: 600;
    color: #777e90;
  }

  .euiTableHeaderButton {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .euiTableHeaderButton-isSorted .euiTableSortIcon {
    fill: #777e90;
  }
}

.euiTableRowCell {
  border-top: 0;
  border-bottom: 0;

  @media (min-width: 48rem) {
    display: block;
  }

  @media (max-width: 1199px) {
    .euiTableRowCell__mobileHeader.euiTableRowCell--hideForDesktop {
      display: block !important;
      max-width: 100%;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      word-wrap: normal !important;
      font-size: 9.625px;
      font-size: 0.6875rem;
      display: block;
      color: #98a2b3;
      padding: 8px;
      padding-bottom: 0;
      margin-bottom: -8px;
      min-height: 24px;
    }
  }

  @media (max-width: 1199px) {
    display: block;
    min-width: 33.33%;
    border: none;
  }

  &.euiTableRowCell--middle {
    align-self: center;
  }
}

.euiTableCellContent {
  @media (min-width: 1199px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .euiHealth {
    vertical-align: middle;
  }
}

// Table Rows per page
.euiButtonEmpty[data-test-subj='tablePaginationPopoverButton'] {
  height: auto;
  border: 0;
  padding: 0;
  font-size: 0.875rem;
  color: #777e90;

  .euiButtonContent {
    padding: 0;
  }

  &:focus {
    background-color: transparent;
  }
}

// Pagination
#paginate .pagination {
  --bs-pagination-color: #fff;
  --bs-pagination-bg: rgba(255, 255, 255, 0.05);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: none;
  --bs-pagination-border-radius: 0.3rem;
  --bs-pagination-hover-color: #fff;
  --bs-pagination-hover-bg: rgb(0, 197, 154);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: #fff;
  --bs-pagination-focus-bg: rgba(0, 197, 154);
  --bs-pagination-focus-box-shadow: rgba(0, 197, 154);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: rgba(0, 197, 154, 0.8);
  --bs-pagination-active-border-color: rgba(0, 197, 154);
  --bs-pagination-disabled-color: #fff;
  --bs-pagination-disabled-bg: rgba(255, 255, 255, 0.2);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  li.page-item {
    margin: 0 0.25rem;
    border: none;
  }
  li.page-item {
    button {
      background-color: rgba(0, 197, 154, 0.5) !important;
      color: #ffffff;
    }
    a.euiButtonEmpty {
      background: rgba(255, 255, 255, 0.05);
      color: #ffffff;

      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      border: 0;
      text-align: center;
      text-decoration: none;
      font-size: 0.875rem;
      margin: 0 0.25rem;

      :hover {
        background-color: rgb(0, 197, 154);
        background-size: 100%;
        font-weight: bolder;

        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        text-decoration: none;
        font-size: 0.875rem;
        margin-left: -5px;
        border-radius: 5px;
      }
    }
  }




  .euiButtonEmpty {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border: 0;
    text-align: center;
    text-decoration: none;
    font-size: 0.875rem;
    color: #353945;
    margin: 0 0.25rem;

    &:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .euiButtonContent {
    padding: 0;
    font-weight: 600;
  }

  .euiPaginationButton-isActive {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.euiPaginationArrowButton:not([class*='isDisabled']):hover,
.euiPaginationArrowButton:not([class*='isDisabled']):focus,
.euiPaginationArrowButton:not([class*='isDisabled']):focus-within {
  background-color: rgba($color: #e2007a, $alpha: 0.5);
  transform: none;
  color: #fff;
}

// Search on Filters
.euiPopoverTitle {
  border-bottom: none;
  border-block-end: none !important;

  .euiFormControlLayout {
    height: 40px;
  }

  .euiFieldSearch {
    height: 40px;
  }

  .euiFormControlLayoutIcons {
    height: 38px;
  }
}

.eui-yScroll::-webkit-scrollbar {
  width: 4px;
}

.eui-yScroll::-webkit-scrollbar-thumb {
  border: 0;
}

.connect-wallet-modal {
  background-color: rgba(255, 255, 255, 0.65);
  .modal-dialog .modal-content {
    background-color: rgb(25, 29, 52);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 20px;
    outline: none;
    padding: 20px;

    .modal-body {
      button.connect-button {
        width: 100%;
        clear: both;
        color: #fff;
        margin-bottom: 10px;
      }
    }
  }
}

.green-button, a.green-button {
  box-sizing: border-box;
  border: none !important;
  background: rgba(0, 197, 154, 0.8) !important;
  color: rgb(245, 246, 252);
}

.green-button:hover, a.green-button:hover {
  background: rgba(0, 197, 154, 1) !important;
}
table.table-transaction {
  background-color: transparent !important;
  --bs-table-bg: transparent;
  --bs-table-color: #fff;
  tr {
    margin-bottom: 0.5rem;
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      td:first-child, th:first-child, td:nth-child(2), th:nth-child(2), td:nth-child(4), th:nth-child(4) {
        width: 15%;
        overflow: hidden;
      }
      td:nth-child(3), th:nth-child(3) {
        width: 55%;
      }
    }
  }
  thead tr, tbody tr {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 0.5rem;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  @media (max-width: 767px) {
    thead {
      display: none;
    }
    tr {
      display: grid;
    }
  }
}

.show-less-767 {
  display: none !important;
}
@media (max-width: 767px) {
  .show-less-767 {
    display: inline !important;
  }
}